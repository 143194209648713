import * as React from "react"
import styled from "@emotion/styled"
import gs from "../components/styling/GlobalStyles"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Title from "../components/styling/Title"

const title = "Contact"

// #region styling
const Form = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
  gap: 20px;
`
const Input = styled.input`
  ${gs.font.default};
  font-size: ${gs.size.font.lg};
  box-sizing: border-box;
  background-color: ${gs.color.darkGreen};
  width: 100%;
  border: none;
  color: ${gs.color.white};
  padding: 8px 12px;
  border-radius: 8px;
  &::placeholder {
    color: ${gs.color.placeholder};
  }
`
const DivInputWrapper = styled.div`
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`
const DivField = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  flex-grow: 1;
  gap: 4px;
`
const DivTextAreaWrap = styled(DivInputWrapper)`
  padding: 0;
`
const Textarea = styled.textarea`
  ${gs.font.default};
  box-sizing: border-box;
  resize: vertical;
  background-color: ${gs.color.darkGreen};
  width: 100%;
  border: 0;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: ${gs.size.font.lg};
  color: ${gs.color.white};
  min-height: 42px;
  &::placeholder {
    color: ${gs.color.placeholder};
  }
`
const Label = styled.label`
  ${gs.font.default};
  font-size: ${gs.size.font.md};
  font-style: italic;
  line-height: 1;
  font-weight: 700;
  color: ${gs.color.graey};
  width: 100%;
`
const ButtonSubmit = styled.button`
  font-size: ${gs.size.font.lg};
  @media all and (min-width: ${gs.size.font.lg}) {
    font-size: ${gs.size.font.xl};
  }
`
const DivNameEmail = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  @media all and (min-width: ${gs.size.screen.md}) {
    flex-wrap: nowrap;
    width: 100%;
  }
`
// #endregion styling

/**
 * @returns the /contact Page
 */
const ContactPage = () => {
  return (
    <Layout>
      <section id="Contact-Form">
        <Title>Contact</Title>
        <Form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input
            type="hidden"
            name="form-name"
            value="contact"
            aria-hidden="true"
          />
          <DivNameEmail>
            <DivField>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                placeholder="Jay Doe"
              />
            </DivField>
            <DivField>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="example@email.com"
              />
            </DivField>
          </DivNameEmail>
          <DivField>
            <Label htmlFor="subject">Subject</Label>
            <Input
              type="text"
              name="subject"
              id="subject"
              aria-label="subject"
              placeholder="Subjects Are Weird, Write What You Want"
            />
          </DivField>
          <DivField>
            <Label htmlFor="message">Message</Label>
            <DivTextAreaWrap>
              <Textarea
                name="message"
                id="message"
                rows={5}
                aria-label="message"
                placeholder="Only nice messages allowed. Maybe try: Hi Megan! Your music is so cool and mystical! Can we pay you to make some music?"
              />
            </DivTextAreaWrap>
          </DivField>
          <ButtonSubmit type="submit" name="submit">
            Send Message
          </ButtonSubmit>
        </Form>
      </section>
    </Layout>
  )
}

export default ContactPage

export const Head = () => <Seo title={title} />
